import { LoadingContext } from '@pokemon/design.ui.loading-spinner'
import { useCallback, useContext } from 'react'

const useHandlePromise = (setError: (text?: string) => void) => {
  const { setLoading } = useContext(LoadingContext)
  const dispatch = useCallback((promise: Promise<unknown>) => {
    setLoading(true)
    promise.catch(err => {
      console.error(err)
      setError()
    }).finally(() => {
      setLoading(false)
    })
  }, [setError, setLoading])
  return dispatch
}

export default useHandlePromise
