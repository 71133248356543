import { useContext } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Form, Field, FieldMetaState } from 'react-final-form'
import { Modal } from '@pokemon/design.ui.containers.modal'
import { TextField } from '@pokemon/design-v2.text-field'
import { Button } from '@pokemon/design-v2.button'
import { Spinner } from '@pokemon/design.ui.loading-spinner'
import { activateUser } from '../../utils/tamApi'
import { AccessToken, AuthContext } from '../../utils/auth/context'
import { ModalContext } from '@pokemon/design.ui.containers.base-modal'
import { FormApi } from 'final-form'

export interface CodeFormValues {
  verificationCode?: string
}

interface VerificationCodeModalProps {
  email?: string
  accessToken?: AccessToken
  childGuid?: string
  username?: string
  resendCode: () => void
  setOpenEmailChangedToast: (status: boolean) => void
  setError: () => void
  cleanEmailChangeForm: () => void
}

export const VerificationCodeModal = (props: VerificationCodeModalProps) => {
  const { email, accessToken, childGuid, username, resendCode, setError, setOpenEmailChangedToast, cleanEmailChangeForm } = props
  const { closeModal } = useContext(ModalContext)
  const { loggedUserProfile, setLoggedUserProfile } = useContext(AuthContext)
  const { t } = useTranslation()

  const onSubmitCode = async (formValues: CodeFormValues, form: FormApi<CodeFormValues, Partial<CodeFormValues>>, callback: any) => {
    const { verificationCode } = formValues
    try {
      if (username) {
        await activateUser(accessToken!.token, username, verificationCode!, childGuid)
        cleanEmailChangeForm()
        closeModal('verificationCodeModal')
        setOpenEmailChangedToast(true)
        callback()

        // Update logged-in user profile with new email
        setLoggedUserProfile({
          ...loggedUserProfile!,
          email: email!
        })
      }
    } catch (error: any) {
      if (error.response.status === 404) {
        form.reset({ verificationCode: undefined })
        callback({ verificationCode: t('invalid_code_error', 'The verification code is incorrect or has expired.') })
      } else {
        closeModal('verificationCodeModal')
        callback()
        setError()
      }
    }
  }

  const computeErrorText = (meta: FieldMetaState<any>) => {
    const { submitError, dirty, error, touched } = meta
    if (submitError && !dirty) {
      return submitError
    } else if (error && touched) {
      return error
    } else {
      return undefined
    }
  }

  return <Modal
    name="verificationCodeModal"
    title={t('enter_verification_code', 'Enter the Verification Code')}
    contentBoxProps={{
      onClose: () => {
        closeModal('verificationCodeModal')
        cleanEmailChangeForm()
      },
      Logo: null
    }}
  >
    <div className='modal-contents'>
      <div className='verification_code_message_section'>
        <Trans
          t={t}
          i18nKey='verification_code_message'
          defaults='We’ve sent an email to <strong>{{email}}</strong>. Enter the 6-digit verification code.'
          values={{ email: email }}
          className='confirmation-message'
        />
      </div>
      <Form
        onSubmit={onSubmitCode}
        validate={(values: CodeFormValues) => {
          const errors: CodeFormValues = {}
          if (!values.verificationCode || values.verificationCode?.length < 6) {
            errors.verificationCode = t('error_code_size', 'The code you entered is invalid.')
          }
          return errors
        }}
        render={({ handleSubmit, submitting, form, hasValidationErrors, hasSubmitErrors, modifiedSinceLastSubmit }) => {
          const handleFocusVerificationCode = () => {
            if (form.getState().hasSubmitErrors) {
              form.restart()
            }
          }
          return (
            <form onSubmit={handleSubmit}>
              {
                submitting && <Spinner altText={t('pokemon_spinner', 'Loading')} />
              }
              {
                !submitting && <>
                  <Field name="verificationCode">
                    {({ input, meta }) => (
                      <TextField
                          id='verification-code-field'
                          sx={{ marginTop: '10px', marginBottom: '10px' }}
                          errorText={computeErrorText(meta)}
                          placeholder={t('verification_code_placeholder', 'Enter the 6-digit verification code')}
                          {...input}
                          onFocus={handleFocusVerificationCode}
                        />
                    )}
                  </Field>
                  <div className='resend-code-section'>
                    <button className='resend-code' data-testid="resend-code-button-test" onClick={resendCode}>{t('resend_activation_code', 'Resend Activation Code')}</button>
                  </div>
                </>
              }
              <Button
                text={t('verify', 'Verify')}
                buttonStatus='primary'
                data-testid="verification-code-button-test"
                type="submit"
                disabled={submitting || hasValidationErrors || (hasSubmitErrors && !modifiedSinceLastSubmit)}
                style={{ marginTop: 40 }}
              />
            </form>
          )
        }
        }
      />
    </div>
  </Modal>
}
