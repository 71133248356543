import { Form, Field } from 'react-final-form'
import * as EmailValidator from 'email-validator'
import React, { useContext, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { getSupportLink } from '@tpci/i18next-ext'
import { checkField } from '../../utils/tamApi'
import { BasicCheckbox } from '@pokemon/design.ui.input.basic-checkbox'
import { Button } from '@pokemon/design-v2.button'
import { TextField } from '@pokemon/design-v2.text-field'
import { Callout } from '@pokemon/design.ui.containers.callout'
import { LoadingContext } from '@pokemon/design.ui.loading-spinner'
import { AuthContextValue, AuthContext } from '../../utils/auth/context'

interface EmailFormProps {
  saveEmail: (email: string, useParentEmail: boolean) => void
  setError: () => void
}

interface FormValues {
  email?: string
  emailConfirm?: string
}

export const ChildEmail = (props: EmailFormProps) => {
  const { setError, saveEmail } = props
  const { t, i18n } = useTranslation()
  const { setLoading } = useContext(LoadingContext)
  const [useParentEmail, setUseParentEmail] = useState(false)
  const [errorEmailValidation, setErrorEmailValidation] = useState(false)
  const { accessToken, primaryUserProfile } = useContext<AuthContextValue>(AuthContext)
  const supportLink = getSupportLink(i18n.language)

  let parentEmail: string | undefined

  if (primaryUserProfile) {
    parentEmail = primaryUserProfile.email
  }

  const validateEmail = (t: (key: string, fallback: string) => string) => (value: string) => {
    if (!EmailValidator.validate(value)) {
      return t('error_invalid_email', 'Please enter a valid email address')
    }
  }

  const handleFocusEmail = () => {
    if (errorEmailValidation) {
      setErrorEmailValidation(false)
    }
  }

  const onSubmit = async (formValues: FormValues) => {
    if (parentEmail && useParentEmail) saveEmail(parentEmail, useParentEmail)

    else if (accessToken && formValues.email && !useParentEmail) {
      const { email } = formValues
      setLoading(true)
      try {
        const emailCheck = await checkField(accessToken.token, { email })
        const answer = emailCheck.data.email

        if (answer) {
          setErrorEmailValidation(true)
        } else {
          saveEmail(email, useParentEmail)
        }
      } catch (e) {
        console.log(e)
        setError()
      }
      setLoading(false)
    }
  }

  const handleParentEmailCheckboxCheck = async () => {
    setUseParentEmail((prev) => !prev)
  }

  return <Form
    onSubmit={onSubmit}
    validate={(values: FormValues) => {
      const errors: FormValues = {}
      if (values.email?.toLocaleLowerCase() !== values.emailConfirm?.toLocaleLowerCase()) {
        errors.emailConfirm = t('error_matching_email', 'Emails must match')
      }
      return errors
    }}
    destroyOnUnregister
    render={
      ({ handleSubmit, submitting, valid }) => (
        <form className='centered-text' onSubmit={handleSubmit}>
          <h1>{t('email_title', 'What is Your Child’s Email')}</h1>
          {!useParentEmail &&
            <>
              <Field
                name="email"
                validate={validateEmail(t)}
              >
                {({ input, meta }) => (
                  <TextField
                      id='email-text-input-test'
                      placeholder={t('email_placeholder_one', 'Email')}
                      errorText={meta.error && meta.touched && <span className='input-error'>{meta.error}</span>}
                      {...input}
                      onFocus={handleFocusEmail}
                    />
                )}
              </Field>
              {errorEmailValidation && <div className='error-email-validation'>
                <Trans t={t} i18nKey='email_validation_error_w_link'
                  defaults='There is already an account with this email, or the email is not valid. If you think this is a mistake, <customerServiceLink>contact customer service</customerServiceLink>'
                  components={{ customerServiceLink: <a href={supportLink} className='error-email-validation-support'></a> }} />
              </div>}
              <Field
                name="emailConfirm"
                validate={validateEmail(t)}
              >
                {({ input, meta }) => (
                  <TextField
                      id='emailConfirm-text-input-test'
                      sx={{ marginTop: '10px', marginBottom: '10px' }}
                      placeholder={t('email_placeholder_two', 'Confirm Email')}
                      errorText={meta.error && meta.touched && <span className='input-error'>{meta.error}</span>}
                      {...input} />
                )}
              </Field>
            </>
          }
          {useParentEmail &&
            <Field
                name="parent-email"
              >
                {({ input }) => (
                  <TextField
                      id='parent-email-field'
                      {...input}
                      disabled
                      value={parentEmail}
                    />
                )}
              </Field>
          }
          <Field
            name="parent_email_checkbox"
            type="checkbox"
          >
            {({ input }) => (
              <BasicCheckbox
                {...input}
                id="parent-email-checkbox"
                data-testid="parent-email-checkbox-test"
                label={t('use_parent_email', 'Use my email address instead')}
                onChange={handleParentEmailCheckboxCheck}
              />
            )}
          </Field>
          <Callout className='email-callout'>
            {t('email_type_warning', 'Please do not use an email address that you may lose access to, such as school or work emails.')}
          </Callout>
          <Button
            text={t('continue_button', 'Continue')}
            data-testid="email-submit-button"
            type="submit"
            disabled={submitting || !valid}
          />
        </form>
      )
    }
  />
}
