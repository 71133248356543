import { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BaseContentBox } from '@pokemon/design.ui.containers.base-content-box'
import { pokemonGoPermissionsPanels } from '@pokemon/ptc-user.ui.preconfigured.pokemon-go-permissions'
import { AuthContext, AuthContextValue } from '../../utils/auth/context'
import { getChildGoSettings, setChildGoSettings } from '../../utils/tamApi'
import { Spinner } from '@pokemon/design.ui.loading-spinner'
import './pokemon_go_game_page.css'
import { countryCodeReplacementString, localizeLink } from '@tpci/i18next-ext'

const Friends = pokemonGoPermissionsPanels[0]
const PartyPlay = pokemonGoPermissionsPanels[1]
const SponsoredContent = pokemonGoPermissionsPanels[2]

export interface PokemonGoGamePageProps {
  setError: (text?: string) => void
}

export interface GoSettings {
  friends: boolean
  party_play: boolean
  sponsored_content: boolean
}

export function PokemonGoGamePage (props: PokemonGoGamePageProps) {
  const { setError } = props
  const { t, i18n } = useTranslation()
  const { isChildView, accessToken, selectedAccount } = useContext<AuthContextValue>(AuthContext)
  const [goSettings, setGoSettings] = useState<GoSettings>()

  const privacyLink = localizeLink(i18n.language, `https://www.pokemon.com/${countryCodeReplacementString}/privacy-notice/`)

  const updateGoSettings = async (settings: GoSettings) => {
    try {
      setGoSettings(settings)
      await setChildGoSettings(accessToken!.token, settings, selectedAccount!.guid)
    } catch (e) {
      console.log(e)
      setError()
    }
  }

  useEffect(() => {
    const getGoSettings = async () => {
      try {
        const res = await getChildGoSettings(accessToken!.token, selectedAccount!.guid)
        setGoSettings(res.data)
      } catch (e) {
        console.log(e)
        setError()
      }
    }

    setGoSettings(undefined)
    if (isChildView) {
      getGoSettings()
    }
  }, [isChildView, accessToken, selectedAccount, setError])

  return (
    <>
      {isChildView && (
      <BaseContentBox customWidth={700} autoSizeBox={true} className='pokemon-go-child-permissions' title={<h1>{t('pokemon_go_permissions_header', 'Permissions for Your Child')}</h1>}>
        {goSettings && (
        <div>
          <Friends
            id='friends-toggle'
            checked={goSettings.friends}
            onChange={ () => {
              if (goSettings.party_play && goSettings.friends) {
                updateGoSettings({ ...goSettings, friends: !goSettings.friends, party_play: false })
              } else {
                updateGoSettings({ ...goSettings, friends: !goSettings.friends })
              }
            }}
            style={{ textAlign: 'start', marginBottom: '30px' }}
          />
          { goSettings.friends && (<PartyPlay
            id='party-play-toggle'
            checked={goSettings.party_play}
            onChange={ () => {
              updateGoSettings({ ...goSettings, party_play: !goSettings.party_play })
            }}
            style={{ textAlign: 'start', marginBottom: '30px' }}
          />)}
          <SponsoredContent
            id='sponsored-content-toggle'
            checked={goSettings.sponsored_content}
            onChange={ () => {
              updateGoSettings({ ...goSettings, sponsored_content: !goSettings.sponsored_content })
            }}
            style={{ textAlign: 'start', marginBottom: '30px' }}
          />
        </div>
        )}
        {!goSettings && (
          <Spinner altText={t('pokemon_spinner', 'Loading')} />
        )}
      </BaseContentBox>
      )}
      <BaseContentBox className="pokemon-go-terms" customWidth={700} autoSizeBox={true} title={<h1>{t('pokemon_go_terms_header', 'Terms of Service')}</h1>}>
        <p>
          <Trans
            t={t}
            i18nKey='pokemon_go_terms_niantic_terms'
            defaults="Please read and review the <termsLink>Niantic Terms of Service</termsLink>."
            components={{ termsLink: <a target='_blank' rel="noreferrer" href="https://www.nianticlabs.com/terms" /> }}
          />
        </p>
        <p>
          <Trans
            t={t}
            i18nKey='pokemon_go_terms_niantic_privacy_policy'
            defaults="Niantic operates Pokémon GO. Please see Niantic’s <privacyPolicyLink>Privacy Policy</privacyPolicyLink> for information about how Niantic uses your personal information."
            components={{ privacyPolicyLink: <a target='_blank' rel="noreferrer" href="https://www.nianticlabs.com/privacy/" /> }}
          />
        </p>
        <p>
          <Trans
            t={t}
            i18nKey='pokemon_go_terms_niantic_terms_links'
            defaults="Clicking on any of the Niantic links above will take you to Niantic-operated sites where privacy and security polices differ."
          />
        </p>
        <p>
          <Trans
            t={t}
            i18nKey='pokemon_go_terms_niantic_privacy_notice'
            defaults="For information on Pokémon’s use of your personal information, please see our <privacyNoticeLink>Privacy Notice.</privacyNoticeLink>"
            components={{ privacyNoticeLink: <a target='_blank' rel="noreferrer" href={privacyLink} /> }}
          />
        </p>
      </BaseContentBox>
    </>
  )
}
