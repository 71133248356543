import { useContext, useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BaseContentBox } from '@pokemon/design.ui.containers.base-content-box'
import { Alert } from '@pokemon/design-v2.alert'
import { Button } from '@pokemon/design-v2.button'
import { Form } from 'react-final-form'
import { LoadingContainer, LoadingContext, LoadingContextValue, LoadingSpinner } from '@pokemon/design.ui.loading-spinner'
import { countryCodeReplacementString, localizeLink, getTwoCharacterCountryCodeFromLanguage, getSupportLink } from '@tpci/i18next-ext'
import { AuthContext, AuthContextValue } from '../../utils/auth/context'
import { updatePpid } from '../../utils/tamApi'
import './nintendo_link_page.css'
import { base64URLEncode, sha256 } from '../../utils/auth/oauth'
import cryptoBrowserify from 'crypto-browserify'
import config from '../../utils/config'
import { useSearchParams } from 'react-router-dom'
import { AccountPickerAccount } from '@pokemon/design.ui.menu.account-picker'
import useHandlePromise from '../../utils/useHandlePromise'

export interface NintendoLinkPageProps {
  setError: (text?: string) => void
  switchAccount: (account: AccountPickerAccount) => void
  getLoggedUserProfile: (childGuid?: string) => Promise<void>
}

export function NintendoLinkPage (props: NintendoLinkPageProps) {
  const { t, i18n } = useTranslation()
  const { selectedAccount, isChildView, accessToken, accounts, loggedUserProfile, setLoggedUserProfile } = useContext<AuthContextValue>(AuthContext)
  const { setError, switchAccount, getLoggedUserProfile } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const handlePromise = useHandlePromise(setError)
  const nintendoAuthCode = searchParams.get('code')
  const nintendoSessionState = searchParams.get('state')
  const [isNintendoLoading, setIsNintendoLoading] = useState(!!nintendoAuthCode)

  const { setLoading } = useContext<LoadingContextValue>(LoadingContext)

  function nintendoCountryCode () {
    const NINTENDO_LEGAL_DOCS_SUPPORTED_COUNTRIES = ['FR', 'IT', 'DE', 'ES', 'GB', 'US', 'DK', 'FI', 'NL', 'SE', 'BR', 'RU']
    const countryCode = getTwoCharacterCountryCodeFromLanguage(i18n.language)
    if (NINTENDO_LEGAL_DOCS_SUPPORTED_COUNTRIES.includes(countryCode)) {
      return countryCode
    } else if (i18n.language === 'es-xl') {
      return 'MX'
    } else {
      return 'US'
    }
  }

  const privacyLink = localizeLink(i18n.language, `https://www.pokemon.com/${countryCodeReplacementString}/privacy-notice/`)
  const nintendoTermsLink = (`https://accounts.nintendo.com/term/eula/${nintendoCountryCode()}`)
  const nintendoPrivacyLink = (`https://accounts.nintendo.com/term/privacy_policy/${nintendoCountryCode()}`)
  const supportLink = getSupportLink(i18n.language)

  const handleSubmit = useCallback(async () => {
    setLoading(true)
    try {
      const randomString = base64URLEncode(cryptoBrowserify.randomBytes(32))
      const sessionState = base64URLEncode(sha256(randomString))

      // store data into session storage for redirect later
      window.sessionStorage.setItem('session_state', sessionState)
      if (isChildView && selectedAccount) window.sessionStorage.setItem('child_guid', selectedAccount.guid)

      const searchParams = new URLSearchParams({ client_id: config.NINTENDO_CLIENT_ID, response_type: 'code', scope: 'user.birthday', redirect_uri: config.NLINK_REDIRECT_URI, state: sessionState })

      // redirect to Nintendo
      const redirectUrl = `${config.NLINK_AUTHORIZATION_URL}?${searchParams}`
      window.location.assign(redirectUrl)
    } catch (error) {
      console.error(error)
      setError()
    } finally {
      setLoading(false)
    }
  }, [isChildView, selectedAccount, setError, setLoading])

  // kick off nintendo settings load or update if necessary
  useEffect(() => {
    // don't kick this off until we have the data we need
    const storedGuid = window.sessionStorage.getItem('child_guid')
    const storedSession = window.sessionStorage.getItem('session_state')
    if (accessToken && nintendoSessionState && nintendoAuthCode && accounts?.length && storedSession) {
      window.sessionStorage.removeItem('session_state')
      window.sessionStorage.removeItem('child_guid')
      if (nintendoSessionState !== storedSession) {
        console.error('Authentication issue on return from Nintendo')
        setError()
      } else {
        if (storedGuid) {
          const account = accounts.find((account) => account.guid === storedGuid)
          if (account) {
            switchAccount(account)
          } else {
            console.error('Attempted to setup Nintendo link for nonexistent child')
            setError()
          }
        }
        // Reset user profile
        setLoggedUserProfile(undefined)
        setIsNintendoLoading(true)
        setSearchParams(new URLSearchParams(''))

        const promise = updatePpid(accessToken!.token, nintendoAuthCode, storedGuid || undefined).then(() => {
          return getLoggedUserProfile(storedGuid || undefined)
        }).then(() => {
          setIsNintendoLoading(false)
        })

        handlePromise(promise)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, accounts, handlePromise, nintendoSessionState, isNintendoLoading])
  return (
      <LoadingContainer altText={t('loading_spinner', 'Loading...')}>
        {/* Yes, this is gross. We _really_ need to switch to Suspense so this is a hack until we do so. */}
        {isNintendoLoading && (<LoadingSpinner altText={t('loading_spinner', 'Loading...')} />)}
        {loggedUserProfile?.nintendo_ppid && !isNintendoLoading && (
          <BaseContentBox key="nintendo-link-enabled" className="nintendo-link-terms" customWidth={700} autoSizeBox={true} title={<div className='app-game-title'><img className='app-game-logo' src='https://static.pokemon.com/images/image 11.png' alt="" /><h1>{t('nintendo_link_header', 'Nintendo Link')}</h1></div>}>
            <Form
              onSubmit={handleSubmit}
              render={
                ({ handleSubmit }) => (
                  <form className='nintendo-link-form' data-testid='nintendo_link_enabled_test' onSubmit={handleSubmit}>
                    <div>
                      <Alert severity="success" variant='outlined'>{t('nintendo_link_success', 'Congratulations! You are all set.')}</Alert>
                      <p>{t('nintendo_link_connected_one', 'Your Nintendo Account and your Pokémon Trainer Club account are now linked. You will now be able to earn Championship Points when competing in video game Online Competitions.')}</p>
                      <p>{t('nintendo_link_connected_two', 'To unlink your accounts, go to your Nintendo Account. If your accounts are unlinked, you will no longer be eligible to receive Championship Points from Online Video Game Competitions in which a Nintendo Account linked to a Pokémon Trainer Club account is required. Championship Points that are awarded to a Pokémon Trainer Club account’s Player ID will remain even if the Nintendo Account has been unlinked.')}</p>
                    </div>
                  </form>
                )
              }
            />
          </BaseContentBox>
        )}
        {loggedUserProfile?.nintendo_ppid.length === 0 && (
          <BaseContentBox key="nintendo-link-disabled" className="nintendo-link-terms" customWidth={700} autoSizeBox={true} title={<div className='app-game-title'><img className='app-game-logo' src='https://static.pokemon.com/images/image 11.png' alt="" /><h1>{t('nintendo_link_header', 'Nintendo Link')}</h1></div>}>
            <Form
              onSubmit={handleSubmit}
              render={
                ({ handleSubmit, submitting }) => (
                  <form className='nintendo-link-form' data-testid='nintendo_link_disabled_test' onSubmit={handleSubmit}>
                    <div>
                      <p>{t('nintendo_link_information_one', 'In order for Championship Points earned playing Online Video Game Competitions on Nintendo Switch Online to be applied to your Player ID, you must first link your Nintendo Account and your Pokémon Trainer Club account. You’ll also need to have opted into the Play! Pokémon program and have a valid Player ID prior to the start of the competition in which the Championship Point rankings are calculated and until after Championship Points are awarded.')}</p>
                      <p>{t('nintendo_link_information_two', 'The button below will redirect you to the Nintendo site where you can log in to an existing Nintendo Account or create a new one. Once you have logged in to your Nintendo Account, you will be asked to link it to your Pokémon Trainer Club account. This will permit Nintendo to share certain account information with Pokémon (such as your date of birth) so that Pokémon can confirm that the same individual controls both the Pokémon Trainer Club account and the Nintendo Account. In order for account ownership to be validated, the date of birth registered to both accounts must match.')}</p>
                      <p>
                        <Trans
                          t={t}
                          i18nKey='nintendo_link_information_three'
                          defaults="While your accounts are linked, Pokémon will also receive from Nintendo the gameplay results of Online Video Game Competitions you participate in. You may request that your accounts be unlinked at any time via the customer service portal: <termsLink>http://support.pokemon.com</termsLink>. If your accounts are unlinked, you will no longer be eligible to receive Championship Points from Online Video Game Competitions in which a Nintendo Account linked to a Pokémon Trainer Club account is required. Championship Points that are awarded to a Pokémon Trainer Club account D’s Player ID will remain even if the Nintendo Account has been unlinked."
                          components={{ termsLink: <a target='_blank' rel="noopener noreferrer" href={supportLink} /> }}
                        />
                      </p>
                      <p>
                        <Trans
                          t={t}
                          i18nKey='nintendo_link_information_four'
                          defaults="Your use of Nintendo Switch Online and the sharing of information from your Nintendo Account to your Pokémon Trainer Club account is subject to <userAgreementLink>Nintendo’s User Agreement</userAgreementLink> and <privacyPolicyLink>Privacy Policy</privacyPolicyLink>, which are read and agreed to when setting up a Nintendo Account."
                          components={{ userAgreementLink: <a target='_blank' rel="noopener noreferrer" href={nintendoTermsLink} />, privacyPolicyLink: <a target='_blank' rel="noopener noreferrer" href={nintendoPrivacyLink} /> }}
                        />
                      </p>
                      <p>
                        <Trans
                          t={t}
                          i18nKey='nintendo_link_privacy_notice'
                          defaults="For information on Pokémon’s use of your personal information, please see our <privacyNoticeLink>Privacy Notice.</privacyNoticeLink>"
                          components={{ privacyNoticeLink: <a target='_blank' rel="noopener noreferrer" href={privacyLink} /> }}
                        />
                      </p>
                      <Button
                        text={t('nintendo_link_button', 'GO TO NINTENDO ACCOUNT')}
                        buttonStatus='primary'
                        data-testid="nintendo-link-button-test"
                        type="submit"
                        disabled={submitting}
                        style={{ width: '100%' }}
                      />
                    </div>
                  </form>
                )
              }
            />
          </BaseContentBox>
        )}
      </LoadingContainer>
  )
}
