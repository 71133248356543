import React, { useContext, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { BaseContentBox } from '@pokemon/design.ui.containers.base-content-box'
import { Button } from '@pokemon/design-v2.button'
import { AuthContextValue, AuthContext } from '../../utils/auth/context'
import * as EmailValidator from 'email-validator'
import { Form, Field, FieldMetaState, FieldInputProps } from 'react-final-form'
import { TextField } from '@pokemon/design-v2.text-field'
import { ModalContext } from '@pokemon/design.ui.containers.base-modal'
import { LoadingContainer, LoadingContext } from '@pokemon/design.ui.loading-spinner'
import { startActivationUser } from '../../utils/tamApi'
import { FormApi } from 'final-form'
import { VerificationCodeModal } from './verification_code_modal'
import Snackbar from '@mui/material/Snackbar'
import './change_email_page.css'
import { responses } from '../../utils/userCreationAPI'

interface ChangeEmailPageProps {
  setSpinnerVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setError: (text?: string) => void
}

interface EmailFormValues {
  email?: string
  emailConfirm?: string
  password?: string
}

export function ChangeEmailPage (props: ChangeEmailPageProps) {
  const { setError } = props
  const { isChildView, selectedAccount, accessToken, loggedUserProfile } = useContext<AuthContextValue>(AuthContext)
  const [password, setPassword] = useState<string>()
  const [newEmail, setNewEmail] = useState<string>()
  const [openResendCodeToast, setOpenResendCodeToast] = React.useState(false)
  const [openEmailChangedToast, setOpenEmailChangedToast] = React.useState(false)
  const { openModal } = useContext(ModalContext)
  const { setLoading } = useContext(LoadingContext)
  const { t } = useTranslation()
  const formRef: React.MutableRefObject<FormApi | null> = useRef(null)
  const titleText = isChildView ? t('change_child_email', 'Change Child’s Email Address') : t('change_email', 'Change Email Address')

  const username = loggedUserProfile?.username ?? undefined
  const childGuid = isChildView ? selectedAccount?.guid : undefined
  const userEmail = loggedUserProfile?.email
  const title = <div className='title'>{titleText}</div>

  const sendCode = async (formValues: EmailFormValues, form: FormApi<EmailFormValues, Partial<EmailFormValues>>, callback : any) => {
    const { email, password } = formValues
    if (email && password && username) {
      initStartActivation(false, accessToken!.token, username, email, password, form, formValues, callback)
    }
  }

  const resendCode = () => {
    if (newEmail && password && formRef.current && username) {
      initStartActivation(true, accessToken!.token, username, newEmail, password, formRef.current)
    }
  }

  const initStartActivation = async (isResend: boolean, accessToken: string, username: string, email: string, password: string, form: FormApi<EmailFormValues, Partial<EmailFormValues>>, formValues?: EmailFormValues, callback? : any) => {
    setLoading(true)
    try {
      await startActivationUser(accessToken, username, email, password, childGuid)
      if (!isResend) {
        setNewEmail(email)
        setPassword(password)
        form.restart()
        openModal('verificationCodeModal')
        callback()
      } else {
        setOpenResendCodeToast(true)
      }
    } catch (error: any) {
      if (!isResend && error.response?.data.errors) {
        checkErrors(error, form, formValues, callback)
      } else {
        if (callback) {
          callback()
        }
        setError()
      }
    }
    setLoading(false)
  }

  const checkErrors = (error: any, form: FormApi<EmailFormValues, Partial<EmailFormValues>>, formValues?: EmailFormValues, callback? : any) => {
    if (error.response.data.errors.password && error.response.data.errors.password.includes('invalid')) {
      form.reset({ ...formValues, password: undefined })
      callback({ password: t('invalid_password', 'The password is incorrect. Please check your password and try again.') })
    } else if (error.response.data.errors.pending_email && error.response.data.errors.pending_email.includes(responses.in_use)) {
      form.reset({ ...formValues, email: undefined, emailConfirm: undefined })
      callback({ email: t('email_validation_error', 'There is already an account with this email, or the email is not valid.'), emailConfirm: 'There is already an account with this email, or the email is not valid.' })
    } else {
      if (callback) {
        callback()
      }
      setError()
    }
  }

  const handleFocusRestartForm = () => {
    if (formRef.current && formRef.current.getState().hasSubmitErrors) {
      formRef.current.restart()
    }
  }

  const validateEmail = (t: (key: string, fallback: string) => string) => (value: string) => {
    if (!EmailValidator.validate(value)) {
      return t('error_invalid_email', 'Please enter a valid email address')
    }
  }

  const closeResendCodeToast = () => {
    setOpenResendCodeToast(false)
  }

  const closeEmailChangedToast = () => {
    setOpenEmailChangedToast(false)
  }

  const cleanEmailChangeForm = () => {
    if (formRef.current) {
      formRef.current.restart()
    }
    setNewEmail(undefined)
    setPassword(undefined)
  }

  const computeErrorText = (meta: FieldMetaState<any>, input : FieldInputProps<string, HTMLElement>) => {
    const { submitError, dirty, error, touched } = meta
    const { name } = input
    if (name !== 'emailConfirm') {
      if (submitError && !dirty) {
        return submitError
      } else if (error && touched) {
        return error
      } else {
        return undefined
      }
    } else {
      if (error && touched && !submitError) {
        return error
      } else {
        return undefined
      }
    }
  }

  return (
    <>
    <LoadingContainer altText={t('loading_spinner', 'Loading...')}>
      <BaseContentBox customWidth={700} autoSizeBox={true} title={title}>
        <div className='user-info'>
          <img className="letter-icon" src="https://static.prod.pokemon.com/images/letter_icon.svg" alt='' />
          <div className='email-info'>
            <strong>{t('email', 'Email')}</strong>
            {userEmail}
          </div>
        </div>
        <div>
          <p>{t('update_message', 'What would you like to update it to?')}</p>
          <p>{t('warning_email_message', 'Please do not use email address that you may lose access to, such as school or work emails.')}</p>
        </div>
        <Form
            onSubmit={ sendCode }
            validate={(values: EmailFormValues) => {
              const errors: EmailFormValues = {}
              if (values.email?.toLocaleLowerCase() !== values.emailConfirm?.toLocaleLowerCase()) {
                errors.emailConfirm = t('error_matching_email', 'Emails must match')
              }
              if (!values.password) {
                errors.password = t('error_required_password', 'Password is required')
              }
              return errors
            }}
            render={({ handleSubmit, submitting, hasValidationErrors, form, hasSubmitErrors, modifiedSinceLastSubmit }) => {
              formRef.current = form
              return (
                <form className='change-email-form' onSubmit={handleSubmit}>
                  <Field
                    name="password"
                  >
                    {({ input, meta }) => (
                      <TextField
                          id='password-text-input-test'
                          errorText={computeErrorText(meta, input)}
                          {...input}
                          onFocus={handleFocusRestartForm}
                          placeholder={t('current_password', 'Current Password')}
                          password
                          required
                        />
                    )}
                  </Field>
                  <Field
                    name="email"
                    validate={validateEmail(t)}
                  >
                    {({ input, meta }) => (
                      <TextField
                          id='email-text-input-test'
                          errorText={computeErrorText(meta, input)}
                          {...input}
                          onFocus={handleFocusRestartForm}
                          placeholder={t('new_email_address', 'New Email Address')}
                          sx={{ marginTop: '10px', marginBottom: '10px' }}
                        />
                    )}
                  </Field>
                  <Field
                      name="emailConfirm"
                      validate={validateEmail(t)}
                  >
                      {({ input, meta }) => (
                      <TextField
                          id='emailConfirm-text-input-test'
                          errorText={computeErrorText(meta, input)}
                          {...input}
                          onFocus={handleFocusRestartForm}
                          placeholder={t('confirm_email_address', 'Confirm Email Address')}
                          sx={{ marginBottom: '16px' }}
                        />
                      )}
                  </Field>
                  <Button
                    text={t('change_email_address', 'Change Email Address')}
                    buttonStatus='primary'
                    data-testid="change-email-button-test"
                    type="submit"
                    disabled= {submitting || hasValidationErrors || (hasSubmitErrors && !modifiedSinceLastSubmit)}
                  />
                </form>
              )
            }
          }
        />
      </BaseContentBox>
      </LoadingContainer>
      <VerificationCodeModal accessToken={accessToken} email={newEmail} childGuid={childGuid} username={username} resendCode={resendCode} setOpenEmailChangedToast={setOpenEmailChangedToast} setError={setError} cleanEmailChangeForm={cleanEmailChangeForm}/>
      <Snackbar
        open={openResendCodeToast}
        autoHideDuration={6000}
        message={<div className='toast-message'>
          <img className='circle-check-icon' src="https://static.pokemon.com/images/green_circle_check.svg" alt="" />
          <p className='toast-text'>{t('code_sent', 'Verification code sent!')}</p>
        </div>}
        onClose={closeResendCodeToast}
      />
      <Snackbar
        open={openEmailChangedToast}
        autoHideDuration={6000}
        message={<div className='toast-message'>
          <img className='circle-check-icon' src="https://static.pokemon.com/images/green_circle_check.svg" alt="" />
          <p className='toast-text'>{t('email_changed', 'Email Changed')}</p>
        </div>}
        onClose={closeEmailChangedToast}
      />
    </>
  )
}
