import React, { useContext, useEffect } from 'react'
import { AuthContextValue, AuthContext } from '../../utils/auth/context'
import config from '../../utils/config'

export function LogoutPage () {
  const { setPrimaryUserAccount, setAccounts, setSelectedAccount, setLoggedUserProfile, setAppList, accessToken } = useContext<AuthContextValue>(AuthContext)

  useEffect(() => {
    const logoutUrl = `${config.OAUTH_PROVIDER_URL}/oauth2/sessions/logout?id_token_hint=${encodeURIComponent(accessToken?.idToken || '')}&post_logout_redirect_uri=${encodeURIComponent(config.TAM_URL)}`

    window.sessionStorage.clear()

    setPrimaryUserAccount(undefined)
    setAccounts(undefined)
    setSelectedAccount(undefined)
    setLoggedUserProfile(undefined)
    setAppList(undefined)

    window.location.assign(logoutUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  )
}
