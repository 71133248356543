import { checkField } from './tamApi'

export const responses = {
  cleared: 'cleared',
  not_clean: 'not_clean',
  in_use: 'in_use',
  too_young: 'too_young',
  too_many: 'too_many',
  not_verified: 'not_verified',
  locked: 'locked'
}

export const checkScreenname = async (screenname: string, accessToken: string | undefined) => {
  if (accessToken) {
    try {
      const response = await checkField(accessToken, { screen_name: screenname })
      const answer = response.data.screen_name
      if (!answer) {
        return responses.cleared
      } else if (answer[0] === responses.not_clean || answer[0] === responses.in_use) {
        return answer[0]
      }
    } catch (error) {
      console.log(error)
    }
  } else {
    console.error('no access token found')
    return undefined
  }
}

export const checkUsername = async (username: string, accessToken: string | undefined) => {
  if (accessToken) {
    try {
      const response = await checkField(accessToken, { username })
      const answer = response.data.username
      if (!answer) {
        return responses.cleared
      } else if (answer[0] === responses.in_use) {
        return answer[0]
      }
    } catch (error) {
      console.log(error)
    }
  } else {
    console.error('no access token found')
    return undefined
  }
}

export const checkCanCreateChild = async (accessToken: string) => {
  try {
    const response = await checkField(accessToken, {})
    const answer = response.data.parent
    if (!answer || answer?.length === 0) {
      return responses.cleared
    } else {
      return answer[0]
    }
  } catch (error) {
    console.log(error)
  }
}
