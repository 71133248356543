import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { Modal } from '@pokemon/design-v2.modal'
import { Button } from '@pokemon/design-v2.button'
import { useTranslation } from 'react-i18next'

export interface SuccessModalProps {
    open: boolean
    onClose: () => void
    isChild?: boolean
}

export const SuccessModal = (props: SuccessModalProps) => {
  const { open, onClose } = props
  const { t } = useTranslation()

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
        <Modal
            id='success-modal'
            className='success-modal'
            open={open}
            onClose={onClose}
            fullScreen={fullScreen}
            sx={{ textAlign: 'center' }}
        >
            <Typography data-testid="mfa-success-title" variant="h5" component="h2">{t('mfa_success_title', 'Multi-factor Authentication Is On!')}</Typography>
            { props.isChild
              ? <>
                    <Typography data-testid="mfa-success-text" variant="body1" sx={{ marginTop: '32px', marginBottom: '32px' }}>{t('mfa_success_text1_child', 'The next time you or your child logs in to their Pokémon Trainer Club account, they will be asked to enter the code from the authenticator app, together with their password. You can change this setting at a later time from your child’s Pokémon Trainer Club account.')}</Typography>
                    <Typography data-testid="mfa-success-text2" variant="body1" sx={{ marginTop: '32px', marginBottom: '32px' }}>{t('mfa_success_text2_child', 'You can change this setting at a later time from your child’s Pokémon Trainer Club account.')}</Typography>
                </>
              : <Typography data-testid="mfa-success-text" variant="body1" sx={{ marginTop: '32px', marginBottom: '32px' }}>{t('mfa_success_text1', 'You’ll be asked to enter your password and the generated code from your authenticator app next time you log in.')}</Typography>
            }
            <Button
                onClick={onClose}
                id="mfa-success-close"
                data-testid="mfa-success-close"
                type="button"
                text={t('close_button', 'Close')}
                style={{ width: '100%', marginTop: 10, textTransform: 'uppercase' }}/>
        </Modal>
  )
}
