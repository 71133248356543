import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { BaseContentBox } from '@pokemon/design.ui.containers.base-content-box'
import { AuthContextValue, AuthContext } from '../../utils/auth/context'
import { PasswordField, validatePassword } from '@pokemon/design.ui.input.password-field'
import { Callout } from '@pokemon/design.ui.containers.callout'
import { TextField } from '@pokemon/design-v2.text-field'
import { Button } from '@pokemon/design-v2.button'
import { changePassword } from '../../utils/tamApi'
import { Field, Form, FieldMetaState, FieldInputProps } from 'react-final-form'
import { FormApi } from 'final-form'
import './change_password_page.css'

interface ChangePasswordPageProps {
  setError: () => void
}

interface FormValues {
  oldPassword?: string
  newPassword?: string
  confirmPassword?: string
}

export function ChangePasswordPage (props: ChangePasswordPageProps) {
  const { setError } = props
  let showCallout = false
  const { isChildView, selectedAccount, accessToken } = useContext<AuthContextValue>(AuthContext)
  const { t } = useTranslation()
  const childGuid = isChildView ? selectedAccount?.guid : ''
  const titleText = isChildView ? t('title_change_child_password_page', 'Change Child’s Password') : t('title_change_password_page', 'Change Password')

  const handleSubmit = async (values: FormValues, form: FormApi<FormValues, Partial<FormValues>>, callback: any) => {
    // call reset password
    const oldPassword = values.oldPassword
    const newPassword = values.newPassword
    try {
      await changePassword(accessToken!.token, oldPassword!, newPassword!, childGuid)
      form.restart({})
      showCallout = true
      callback()
    } catch (error: any) {
      if (error.response?.data.errors) {
        checkErrors(error, form, values, callback)
      } else {
        if (callback) {
          callback()
        }
        setError()
      }
    }
  }

  const checkErrors = (error: any, form: FormApi<FormValues, Partial<FormValues>>, values?: FormValues, callback?: any) => {
    if (error.response.data.errors.password && error.response.data.errors.password.includes('invalid')) {
      callback({ oldPassword: t('error_invalid_password', 'Please enter the correct password') })
    } else if (error.response.data.errors.new_password && error.response.data.errors.new_password.includes('already_used')) {
      callback({ confirmPassword: t('error_used_password', 'Please enter a password that hasn\'t been used before') })
    } else if (error.response.data.errors.new_password && error.response.data.errors.new_password.includes('invalid_characters')) {
      callback({ confirmPassword: t('error_invalid_characters', 'Please only use valid characters in your password') })
    } else {
      if (callback) {
        callback()
      }
      setError()
    }
  }

  const computeErrorText = (meta: FieldMetaState<any>, input: FieldInputProps<string, HTMLElement>) => {
    const { submitError, error, touched } = meta
    if (submitError && !error) {
      return submitError
    } else if (error && touched) {
      return error
    } else {
      return undefined
    }
  }

  return (
    <BaseContentBox autoSizeBox={true} customWidth={700} title={<div className='title'>{titleText}</div>}>
      <Form
        onSubmit={handleSubmit}
        validate={(values: FormValues) => {
          const errors: FormValues = {}
          if (!values.oldPassword) {
            showCallout = false
            errors.oldPassword = t('error_blank_password', 'Field must be filled in')
          }
          if (values.newPassword !== values.confirmPassword) {
            showCallout = false
            errors.confirmPassword = t('error_matching_password', 'Passwords must match')
          }
          return errors
        }}
        render={
          ({ handleSubmit, submitting, hasSubmitErrors, hasValidationErrors, submitError, modifiedSinceLastSubmit }) => (
            <form className='change-password-form' onSubmit={handleSubmit} autoComplete={'new-password'} >
              <div>
                <p className='form-wrapper' id='current-password-label'>
                  {t('current_password', 'Current Password')}
                </p>
              </div>
              <Field
                name="oldPassword"
              >
                {({ input, meta }) => (
                  <TextField
                    id='old-password-text-input-test'
                    password
                    errorText={computeErrorText(meta, input)}
                    placeholder={t('password', 'Password')}
                    {...input}
                    required
                  />
                )}
              </Field>
              <div>
                <p className='form-wrapper' id='new-password-label'>
                  {t('enter_new_password', 'New Password')}
                </p>
              </div>
              <Field name="newPassword"
                validate={text => !validatePassword(text)}
              >
                {({ input }) => (
                  <PasswordField aria-labelledby='new-password-label' {...input} />
                )}
              </Field>
              <p
                style={{
                  fontFamily: 'Flexo-Bold, serif',
                  alignSelf: 'start',
                  paddingTop: '8px'
                }}
              >
                {t('password_requirement_recently_used', "Do not use a password you've used before")}
              </p>
              <div>
                <p className='form-wrapper'>
                  {t('confirm_new_password', 'Confirm Password')}
                </p>
              </div>
              <Field
                name="confirmPassword"
              >
                {({ input, meta }) => (
                  <TextField
                    id='confirm-password-text-input-test'
                    errorText={computeErrorText(meta, input)}
                    placeholder={t('confirm_new_password', 'Confirm Password')}
                    {...input}
                    password
                    required
                    sx={{ marginBottom: '16px' }}
                  />
                )}
              </Field>
              {showCallout && <Callout
                data-testid='callout' variant='success'>
                <p>{
                  t('password_change', 'Password has been successfully changed!')}
                </p>
              </Callout>
              }
              <Button
                className="save-button"
                data-testid='save'
                type={'submit'}
                buttonStatus={'primary'}
                text={t('save_button', 'Save')}
                disabled={submitting || hasValidationErrors || (hasSubmitErrors && !modifiedSinceLastSubmit)}
              />
            </form>
          )
        }
      />
    </BaseContentBox>
  )
}
