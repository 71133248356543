export const getDays = (
  month: string,
  year: string,
  enforceLeapYear: boolean
): number => {
  const shortMonths = ['04', '06', '09', '11']

  if (!month || !year) return 31

  if (shortMonths.includes(month)) {
    return 30
  } else if (month === '02') {
    if (enforceLeapYear && !isLeapYear(year)) {
      return 28
    } else {
      return 29
    }
  } else {
    return 31
  }
}

export const isLeapYear = (yearString: string) => {
  const year = parseInt(yearString, 10)
  const result = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
  return result
}

export const months = (t: (key: string, fallback: string) => string) => [
  t('month_january', 'January'),
  t('month_february', 'February'),
  t('month_march', 'March'),
  t('month_april', 'April'),
  t('month_may', 'May'),
  t('month_june', 'June'),
  t('month_july', 'July'),
  t('month_august', 'August'),
  t('month_september', 'September'),
  t('month_october', 'October'),
  t('month_november', 'November'),
  t('month_december', 'December')
]

export const pokemonDOBFormatter = (date: Date) => {
  return [date.getFullYear(), date.getMonth() + 1, date.getDate()]
    .map((item) => {
      return item.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
    })
    .join('-')
}

/**
 * Returns the user's date of birth in local time
 *
 * For semantics of Date.parse: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#using_date.parse
 */
export const getLocalBirthDate = (user: {readonly date_of_birth: string}): Date => new Date(`${user.date_of_birth}T00:00:00`)
