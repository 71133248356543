import axios, { AxiosResponse } from 'axios'
import config from './config'
import { getRequestConfig } from './tamApi'

export interface MfaStatusPayload {
  /* eslint-disable camelcase */
  mfa_method: string[],
  /* eslint-enable camelcase */
}

export async function getMfaStatus (accessToken: string, childGuid?: string): Promise<AxiosResponse<MfaStatusPayload, any>> {
  await new Promise(resolve => setTimeout(resolve, 100))
  if (childGuid) {
    return axios.get<MfaStatusPayload>(`${config.MFAAPI_BASE_URL}/status/${childGuid}`, {
      ...getRequestConfig(accessToken)
    })
  }
  return axios.get<MfaStatusPayload>(`${config.MFAAPI_BASE_URL}/status`, {
    ...getRequestConfig(accessToken)
  })
}

export interface TotpSecretPayload {
  /* eslint-disable camelcase */
  totp_secret: string,
  qr_data: string,
  enrollment_ticket: string,
  /* eslint-enable camelcase */
}

export async function getTotpSecret (accessToken: string, childGuid?: string): Promise<AxiosResponse<TotpSecretPayload, any>> {
  await new Promise(resolve => setTimeout(resolve, 100))
  if (childGuid) {
    return axios.get<TotpSecretPayload>(`${config.MFAAPI_BASE_URL}/totp-secret/${childGuid}`, {
      ...getRequestConfig(accessToken)
    })
  }
  return axios.get<TotpSecretPayload>(`${config.MFAAPI_BASE_URL}/totp-secret`, {
    ...getRequestConfig(accessToken)
  })
}

export interface SetMfaPayload {
  /* eslint-disable camelcase */
  mfa_method: string
  enrollment_ticket: string
  totp: string
  /* eslint-enable camelcase */
}

export async function setMfa (accessToken: string, payload: SetMfaPayload, childGuid?: string): Promise<void> {
  if (childGuid) {
    return axios.post(`${config.MFAAPI_BASE_URL}/set/${childGuid}`, payload, {
      ...getRequestConfig(accessToken)
    })
  }
  return axios.post(`${config.MFAAPI_BASE_URL}/set`, payload, {
    ...getRequestConfig(accessToken)
  })
}

export interface DeleteMfaPayload {
  /* eslint-disable camelcase */
  mfa_method: string
  totp: string
  /* eslint-enable camelcase */
}

export async function deleteMfa (accessToken: string, payload: DeleteMfaPayload, childGuid?: string): Promise<void> {
  if (childGuid) {
    return axios.delete(`${config.MFAAPI_BASE_URL}/delete/${childGuid}`, {
      data: payload,
      ...getRequestConfig(accessToken)
    })
  }
  return axios.delete(`${config.MFAAPI_BASE_URL}/delete`, {
    data: payload,
    ...getRequestConfig(accessToken)
  })
}
