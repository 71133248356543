import { useTranslation } from 'react-i18next'
import { BaseContentBox } from '@pokemon/design.ui.containers.base-content-box'
import { ErrorFallback } from '@pokemon/design.ui.error.error-fallback'
import { ErrorCustomBody } from './error_custom_body'
import { ReactNode } from 'react'

export interface ErrorPageProps {
  title?: string;
  message?: ReactNode;
}

export function ErrorPage (props: ErrorPageProps) {
  const { message, title } = props
  const { t } = useTranslation()

  return (
    <BaseContentBox >
        <ErrorFallback
            customBody={<ErrorCustomBody title={title || t('error_title', 'Something went wrong')} message={message}/>}
            primaryButton={{
              text: t('primary_button_error_page', 'Contact Pokémon Support'),
              url: 'https://support.pokemon.com'
            }}
            secondaryButton={{
              text: t('secondary_button_error_page', 'Return to Dashboard'),
              url: '/'
            }}
        />
    </BaseContentBox>
  )
}
