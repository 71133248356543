import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { BaseContentBox } from '@pokemon/design.ui.containers.base-content-box'
import { AuthContextValue, AuthContext } from '../../utils/auth/context'
import { Typography } from '@pokemon/design-v2.typography'
import { Button } from '@pokemon/design-v2.button'

export interface PlaySettingsPageProps {
  setError: (text?: string) => void,
}

export function PlaySettingsPage (props: PlaySettingsPageProps) {
  const { loggedUserProfile } = useContext<AuthContextValue>(AuthContext)
  const { t } = useTranslation()

  const hasPlayerId = Boolean(loggedUserProfile?.player_id)

  return (
    <>
      {!hasPlayerId &&
        <BaseContentBox customWidth={700} autoSizeBox={true}
          title={<Typography variant="h5" component="h1">{t('play_pokemon_create_account_title', 'Create a Play! Pokémon Account')}</Typography>}>
          <div style={{ textAlign: 'center' }}>
            <img alt='' aria-hidden='true' style={{ maxWidth: '100%', height: 'auto', marginBottom: 32 }} src={'https://static.pokemon.com/images/play_pokemon_logo.png'} />
          </div>
          <Typography variant='body1'>{t('play_pokemon_create_account_intro', 'Discover the Pokémon TCG and video game community through the Play! Pokémon program. There are Play! Pokémon events for every player, whether you’re new to Pokémon organized play or practicing to become the Pokémon World Champion! The Play! Pokémon program is your opportunity to:')}</Typography>
          <Typography component='ul' style={{ marginTop: 32 }}>
            <Typography variant='body1' component='li'>{t('play_pokemon_create_account_feature1', 'Meet Pokémon fans in your community')}</Typography>
            <Typography variant='body1' component='li'>{t('play_pokemon_create_account_feature2', 'Win prizes, Travel Awards, and more')}</Typography>
            <Typography variant='body1' component='li'>{t('play_pokemon_create_account_feature3', 'Focus on fun')}</Typography>
            <Typography variant='body1' component='li'>{t('play_pokemon_create_account_feature4', 'Aim for higher goals for skilled players')}</Typography>
            <Typography variant='body1' component='li'>{t('play_pokemon_create_account_feature5', 'Play in events that take place all year')}</Typography>
          </Typography>
          <Button disableRipple data-testid="play-pokemon-create-account-start" text={t('play_pokemon_create_account_start_button', 'GET STARTED')} style={{ width: '100%', marginTop: 32 }}/>
        </BaseContentBox>
      }
      {hasPlayerId &&
        <BaseContentBox customWidth={700} autoSizeBox={true}
          title={<Typography variant="h5" component="h1">{t('play_pokemon_settings_title', 'Play! Pokémon Settings')}</Typography>}>
          <Typography variant='body1'>settings page goes here</Typography>
        </BaseContentBox>
      }
    </>
  )
}
